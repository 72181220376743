import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import ContactUs from "./Components/ContactUs/ContactUs";
import Index from "./Pages/Index";
import Jobs from "./Pages/Jobs";
import Footer from "./Components/Footer/Footer";
import 'react-lazy-load-image-component/src/effects/blur.css';
// import BlogDetail from "./Pages/BlogDetail";

function App() {
  return (
    <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/jobs" element={<Jobs />} />
          {/* <Route exact path="/blog-detail/:id" element={ <BlogDetail /> } /> */}
        </Routes>
        
        <ContactUs />
        <Footer />
    </div>
  );
}

export default App;
