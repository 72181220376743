import React, { Suspense } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostYourJob from "../Components/PostYourJob/PostYourJob";
import Seprator from "../Components/Seprator/Seprator";
import CompanySlide from "../Components/CompanySlide/CompanySlide";
import companyLogo1 from "../assets/Images/company-logo1.png";
import companyLogo2 from "../assets/Images/company-logo2.png";
import companyLogo3 from "../assets/Images/company-logo3.png";
import companyLogo4 from "../assets/Images/company-logo4.png";
import companyLogo5 from "../assets/Images/company-logo5.png";
import companyLogo6 from "../assets/Images/company-logo6.png";
import jobImg1 from "../assets/Images/company-job-logo1.png";
import jobImg2 from "../assets/Images/company-job-logo2.png";
import jobImg3 from "../assets/Images/company-job-logo3.png";
import sliderSettings from "../config/SliderSettings.json";
import MyExperience from "../Components/MyExperience/MyExperience";

const CurrentJobBlock = React.lazy(() =>
  import("../Components/CurrentOpeningBlock/CurrentJobBlock")
);

function Jobs() {
  const test = sliderSettings.settings;
  test.slidesToShow = 6;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    arrows: false,
  };
  return (
    <div className="jobs">
      <PostYourJob />
      <div className="companies--hiring">
        <div className="container-lg">
          <Seprator />
          <div className="head-section">
            <h2 className="title">Companies hiring</h2>
          </div>
          <Slider {...test}>
            <CompanySlide
              imgSrc={companyLogo1}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo2}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo3}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo4}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo5}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo6}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo1}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo2}
              companyTitle="Al-Futtaim Group"
            />
            <CompanySlide
              imgSrc={companyLogo3}
              companyTitle="Al-Futtaim Group"
            />
          </Slider>
          <p className="already--applied">
            Already applied but not getting response? you’re definitely not
            using the right approach to get noticed to hiring manager.
            <a href="#">Book your 15 mins session with Nouman</a>
          </p>
        </div>
      </div>
      {/* <div className="current--opening">
        <div className="container-lg">
          <Seprator />
          <div className="head-section">
            <h2 className="title">Current opening</h2>
            <a className="btn post-job" href="#">
              Post your job
            </a>
          </div>
          <p>
            You’ll only get one chance to make an amazing impression to tech
            highest paid companies, don’t waste it!
          </p>
          <div className="opening--job--section">
            <Suspense fallback={<div>Please wait....</div>}>
              <Slider {...settings}>
                <CurrentJobBlock
                  imgSrc={jobImg1}
                  position="Senior User Experience Research Manager"
                  btnJobDetail="#"
                />
                <CurrentJobBlock
                  imgSrc={jobImg2}
                  position="User Experience Design Manager"
                  btnJobDetail="#"
                />
                <CurrentJobBlock
                  imgSrc={jobImg3}
                  position="UI UX Designer"
                  btnJobDetail="#"
                />
                <CurrentJobBlock
                  imgSrc={jobImg2}
                  position="User Experience Design Manager"
                  btnJobDetail="#"
                />
                <CurrentJobBlock
                  imgSrc={jobImg2}
                  position="User Experience Design Manager"
                  btnJobDetail="#"
                />
                <CurrentJobBlock
                  imgSrc={jobImg2}
                  position="User Experience Design Manager"
                  btnJobDetail="#"
                />
                <CurrentJobBlock
                  imgSrc={jobImg2}
                  position="User Experience Design Manager"
                  btnJobDetail="#"
                />
              </Slider>
            </Suspense>
          </div>
          <a className="get--all--access" href="#" target="_blank">
            Subscribe to get access to all jobs & hiring managers for
            $7.99/monthly.
          </a>
        </div>
      </div> */}
      <MyExperience />
    </div>
  );
}

export default Jobs;
