import React from "react";
import "./SubscribedNowModal.css";
import SubscribeImg from "../../../assets/Images/subscribe--now--img.png";

function SubscribeNowModal({ open, onClose }) {
  if (!open) return null;
  return (
    <div className="modal--overlay" onClick={onClose}>
      <div data-aos="zoom-in" className="Modal">
        <div className="modal--subscribe">
          <div className="head-area">
            <p>
              <strong>Nouman</strong>
              product <br></br> designer/<br></br>mentor
            </p>
            <div className="img--holder">
              <img src={SubscribeImg} alt="#" />
            </div>
          </div>
          <div className="subscribe--desc">
            <strong>I’ll be sending you updates on</strong>
            <ul>
              <li>Companies hiring for design roles</li>
              <li>New videos or resources from me</li>
              <li>Product design resources and inspiration</li>
              <li>How to apply for design jobs</li>
              <li>Upcoming event announcements</li>
              <li>Career growth strategy</li>
            </ul>
            <form action="#">
              <fieldset>
                <input type="email" placeholder="Enter Your Email Address" />
                <input type="submit" value="Subscribe now" />
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeNowModal;
