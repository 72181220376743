import React from "react";
import "./MentorshipColumn.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MentorshipColumn(props) {
  return (
    <div className="mentorship--column">
      <div className="mentorship-img-holder">
        <LazyLoadImage className="mentorship--icon" src={props.icon} effect="blur" alt="#" />
      </div>
      <h2 className="column---title">{props.title}</h2>
      <strong>{props.session}</strong>
      <p>{props.description}</p>
      <a
        href={props.bookSession}
        className="btn--book--session"
        rel="noopener"
      >
        Book your session
      </a>
      {/* <Link
        to={props.bookSession}
        className="btn--book--session"
        rel="noopener"
        target="_blank"
      >
        Book your session
      </Link> */}
    </div>
  );
}

export default MentorshipColumn;
