import React from "react";
import "./Nav.css";
import { NavLink } from "react-router-dom";

function Nav() {
  return (
    <nav className="main--nav stroke">
      <ul>
        <li>
          <NavLink to="/">About me </NavLink>
        </li>
        <li>
        {/* <NavLink to="/"  onClick={() => {
                      window.location.replace("#my--work");
                    }}>My Work</NavLink> */}
        <NavLink to="/"  onClick={() => {
                    window.scrollTo(0, 1470);
                    }}>My Work</NavLink>
        </li>
        <li>
          <NavLink to="/"  onClick={() => {
                      // window.location.replace("#mentorship");
                      window.scrollTo(0, 5580);
                    }}>
                    1-1 mentorship</NavLink>
        </li>
        <li>
          <NavLink to="/jobs" onClick={() => window.scrollTo(0)}>Design Jobs</NavLink>
        </li>
        <li>
          <NavLink to="/" onClick={() => {
              window.scrollTo(0, 10000);
          }}>Contact me</NavLink>
        </li>
      </ul> 
    </nav>
  );
}

export default Nav;
