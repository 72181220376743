import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Project.css";

function Project(props) {
  return (
    <>
      <div className="company--block">
          <div className="img--holder">
            <LazyLoadImage
              className="desktop-banner"
              src={props.imgSrc}
              alt="#"
            />
            <LazyLoadImage
              className="mob-banner"
              src={props.imgMobSrc}
              alt="#"
            />
          </div>
          <div className="text--holder">
            <h2 className="title">{props.title}</h2>
            <p className="responsibility">{props.responsibility}</p>
            <p>{props.description}</p>
            <a target="__blank" href={props.caseStudyLink} className="btn read">{props.btnRead}</a>
            {/* <button className="btn read"></button> */}
            <button className="btn confidential" onClick={props.onChildClick}>
              {props.btnContactDesign}
              <span>{props.spanConfidential}</span>
            </button>
          </div>
        </div>
    </>
  );
}

export default Project;
