import { Link } from "react-router-dom";
import BrandImg1 from "../../assets/Images/brand-1.png";
import BrandImg2 from "../../assets/Images/brand-2.png";
import BrandImg3 from "../../assets/Images/brand-3.png";
import BrandImg4 from "../../assets/Images/brand-4.png";
import BrandImg5 from "../../assets/Images/brand-5.png";
import BrandImg6 from "../../assets/Images/brand-6.png";
import BrandImg7 from "../../assets/Images/brand-7.png";
import BrandImg8 from "../../assets/Images/brand-8.png";
import BrandImg9 from "../../assets/Images/brand-9.png";
import BrandImg10 from "../../assets/Images/brand-10.png";
import BrandImg11 from "../../assets/Images/brand-11.png";
import BrandImg12 from "../../assets/Images/brand-12.png";
import BrandImg13 from "../../assets/Images/brand-13.png";
import BrandImg14 from "../../assets/Images/brand-14.png";
import BrandImg15 from "../../assets/Images/brand-15.png";
import BrandImg16 from "../../assets/Images/brand-16.png";
import BrandImg17 from "../../assets/Images/brand-17.png";
import BrandImg18 from "../../assets/Images/brand-18.png";
import BrandImg19 from "../../assets/Images/brand-19.png";
import BrandImg20 from "../../assets/Images/brand-20.png";
import "./Brands.css";
import Seprator from "../Seprator/Seprator";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Brands() {
  return (
    <div className="work--with--brands">
      <div className="container-lg">
        <Seprator />
          <h2 className="title">Organizations and brands <br /> I’ve worked with</h2>
          <div className="brands--logo">
            <ul>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg1} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg2} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg3} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg4} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg5} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg6} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg7} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg8} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg9} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg10} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg11} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg12} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg13} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg14} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg15} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg16} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg17} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg18} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg19} effect="blur" alt="#" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <LazyLoadImage src={BrandImg20} effect="blur" alt="#" />
                </Link>
              </li>
            </ul>
          </div>
            <p>I have a track record of leading transformative design initiatives for prominent global brands such as Bloomingdale's, Harvey Nichols, Gap, Visa, and Mastercard. Additionally, I've collaborated with notable regional entities including Mamas & Papas, Al Tayer Group, Aldar Group, Al Futtaim Group, Dubai Police (Esaad), Nahdi Group, and Saudi Tourism.</p>
            <p>My approach is characterized by a rigorous focus on data and customers. Through meticulous refinement, I have developed a design methodology that places data at the core of decision-making. Simultaneously, I prioritize a customer-centric perspective, ensuring that every design resonates with the intended audience and aligns seamlessly with brand objectives. also, resulting in consistently impactful outcomes.</p>
          <Seprator />
        </div>
      </div>
  );
}

export default Brands;
