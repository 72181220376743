import React, { useState } from "react";
import "./CaseStudyModal.css";
import caseStudyLockImg from "../../../assets/Images/casestudy--lock.jpg";

function CaseStudyModal({ open, onClose }) {
  const [isAlreadyPass, setAfterPassword] = useState(false);
  const contactMe = () => {
    setAfterPassword(true);
  };
  if (!open) return null;
  return (
    <div className="modal--overlay">
      <div data-aos="zoom-in" className="Modal">
        <span data-aos="zoom-out" className="modal--close" onClick={onClose}>
          X
        </span>
        <div className="img-holder">
          <img src={caseStudyLockImg} alt="" />
        </div>
        <div className="modal--content">
          {!isAlreadyPass ? (
            <>
              <strong className="modal--password">Password required</strong>
              <p>
                This case study is highly confidential, and reach out to me if
                you have a valid reason to see it.
              </p>
              <button type="button" className="contact--password" onClick={contactMe}>
                Contact me for password
              </button>
              <span className="already--pass">Already have a password?</span>
              <form>
                <input type="password" placeholder="Enter now" />
              </form>
            </>
          ) : (
            <>
              <div className="get--access">
                <form action="#">
                  <input type="password" placeholder="Enter password here" />
                  <input type="submit" value="Get Access" />
                </form>
                <p>
                  Don’t have a password?
                  <button type="button">Contact me for password</button>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CaseStudyModal;
