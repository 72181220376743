import React from "react";
import "./PostYourJob.css";
import founderThumb from "../../assets/Images/numan--thumbnail.png";

function PostYourJob() {
  return (
    <div className="post--your--job">
      <div className="container-lg">
        <div className="head-sec">
          <h1 className="title">Product & Design jobs board </h1>
          <div className="thumb">
            <img src={founderThumb} alt="" />
          </div>
        </div>
        <p>
          Curated design job board for product designers who are currently and
          planning to work in Middle-East region by Nouman.
        </p>
        <div className="btn-container">
          <button type="button" className="btn mentorship--applying">
            Get mentorship in applying
          </button>
          <button type="button" className="btn job-applying">
            Post your design job
          </button>
        </div>
      </div>
    </div>
  );
}

export default PostYourJob;
