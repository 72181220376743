import React from "react";
import Slider from "react-slick";
import sliderSettings from '../../config/SliderSettings.json'

function MyExperienceSlider(props) {
  const experienceSlider = sliderSettings.settings;
  experienceSlider.slidesToShow = 3;

  const settings = {
    autoplay: true,
  }
  return (
    <div>
      <Slider {...experienceSlider} {...settings}>{props.children}</Slider>
    </div>
  );
}

export default MyExperienceSlider;
