import React from "react";
import TestimonialImg1 from "../../assets/Images/TestimonialImg1.webp";
import TestimonialImg2 from "../../assets/Images/TestimonialImg2.webp";
import TestimonialImg3 from "../../assets/Images/TestimonialImg3.webp";
import TestimonialImg4 from "../../assets/Images/TestimonialImg4.webp";
import TestimonialImg5 from "../../assets/Images/TestimonialImg5.webp";
import TestimonialImg6 from "../../assets/Images/TestimonialImg6.webp";
import TestimonialImg7 from "../../assets/Images/TestimonialImg7.webp";
import TestimonialImg8 from "../../assets/Images/TestimonialImg8.webp";
import TestimonialImg9 from "../../assets/Images/TestimonialImg9.webp";
import "./Testimonials.css";
import Seprator from "../Seprator/Seprator";

function Testimonials() {
  return (
    <div className="testomonial--sec">
      <div className="container-lg">
        <Seprator />
        <h2 className="title">
          What people say about Nouman
        </h2>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="testimonial-img">
              <img src={TestimonialImg1} alt="#" />
            </div>
            <div className="testimonial-img">
              <img src={TestimonialImg2} alt="#" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="testimonial-img">
              <img src={TestimonialImg3} alt="#" />
            </div>
            <div className="testimonial-img">
              <img src={TestimonialImg4} alt="#" />
            </div>
            <div className="testimonial-img">
              <img src={TestimonialImg5} alt="#" />
            </div>
            <div className="testimonial-img">
              <img src={TestimonialImg6} alt="#" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="testimonial-img">
              <img src={TestimonialImg7} alt="#" />
            </div>
            <div className="testimonial-img">
              <img src={TestimonialImg8} alt="#" />
            </div>
            <div className="testimonial-img">
              <img src={TestimonialImg9} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
