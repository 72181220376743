import React, { useState} from "react";
import "./MyWork.css";
import Project from "./Project/Project";

import theEntertainerImg from "../../assets/Images/theEntertainer-banner.webp";
import bloomingdalesImg from "../../assets/Images/blooming-banner.webp";
import ounassImg from "../../assets/Images/ounass-banner.webp";
import tradelingImg from "../../assets/Images/tradeling-banner.webp";
import nissnassImg from "../../assets/Images/nissnass-banner.webp";
import theEntertainerMobImg from "../../assets/Images/theEntertainer-mob-banner.webp";
import bloomingdalesMobImg from "../../assets/Images/blooming-mob-banner.webp";
import ounassMobImg from "../../assets/Images/ounass-mob-banner.webp";
import tradelingMobImg from "../../assets/Images/tradeling-mob-banner.webp";
import nissnassMobImg from "../../assets/Images/nissnass-mob-banner.webp";
import CaseStudyModal from "../Modals/CaseStudyModal/CaseStudyModal";

function MyWork() {
  const [modalState, setModalState] = useState(false);

  const openModal = () => {
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  if (modalState) {
    document.body.classList.add("active--modal");
  } else {
    document.body.classList.remove("active--modal");
  }
  const projectData = [
    {
      desktopImg: theEntertainerImg,
      imgMobSrc: theEntertainerMobImg,
      title: "theEntertainer - Discount, Rewards and Loyalty App",
      responsibility:
        "Product Design strategy.  UX/UI  Design.  Design system.  Customer Research.  Usabillity Testing.  Design workshops.  Design System",
      description:
        "Entertainer is a subscription based platform that helps people to save money with their day-to-day activities and experiences such as “Food, Travel, Hotels, Fashion, Beauty and everyday services, Etc.",
      btnRead: "Read full casestudy",
      btnContactDesign: "Contact for Design impact",
      spanConfidential: "- Confidential",
      urlCaseStudy: "https://docs.google.com/presentation/d/1bBS3-cXoMA_a9-fkOFMrayMzU2jgAf2-/edit?usp=share_link&ouid=109335930573484176178&rtpof=true&sd=true"
    },
    {
      desktopImg: bloomingdalesImg,
      imgMobSrc: bloomingdalesMobImg,
      title: "Bloomingdales.ae - Omnichannel experience",
      responsibility:
        "Native Apps / Website / Mobile website.  Product Strategy.  UX/UI  Design.  Design system.  UX Research.  Usabillity Testing.",
      description:
        "I designed a luxury departmental marketplace platform for the GCC region. The platform needs to provide a seamless user experience to online and in-store shoppers.",
      btnRead: "Read full casestudy",
      btnContactDesign: "Contact for Design impact",
      spanConfidential: "- Confidential",
      urlCaseStudy: "/blooming-case-study/index.html"
    },
    {
      desktopImg: ounassImg,
      imgMobSrc: ounassMobImg,
      title: "Ounass.ae - Redesign to uplift revenue",
      responsibility:
        "Native Apps / Website / Mobile website.  Product Strategy.  UX/UI  Design.  Design system.  UX Research.  Usabillity Testing.",
      description:
        "Ounass is a leading luxury fashion marketplace in the MENA Region, enabling brands or designers to sell their products through the platform with 2 hour delivery.",
      btnRead: "Read full casestudy",
      btnContactDesign: "Contact for Design impact",
      spanConfidential: "- Confidential",
      urlCaseStudy: "/ounass-case-study/index.html"
    },
    {
      desktopImg: tradelingImg,
      imgMobSrc: tradelingMobImg,
      title: "Tradeling.com - B2B Marketplace / PDP conversion improvement",
      responsibility:
        "Website / Mobile website.  Product details page conversion.  UX/UI  Design.  Checkout conversion.  UX Research.  Usabillity Testing.",
      description:
        "Improved the conversion rate from the 'Product details page' to 'Submit Enquiry' to a seller by 47.99% simply by restructuring the information architecture and enabling personalized category-driven details pages for buyers.",
      btnRead: "Read full casestudy",
      btnContactDesign: "Contact for Design impact",
      spanConfidential: "- Confidential",
      urlCaseStudy: 'https://drive.google.com/drive/folders/11hy5wvp-Qv5af4XAykUaV9CmRnlLlhBD?usp=sharing'
    },
    {
      desktopImg: nissnassImg,
      imgMobSrc: nissnassMobImg,
      title: "Nisnass.ae - Fast Fashion Social marketplace",
      responsibility:
        "Product Design strategy.  UX/UI  Design.  Customer Research.  Usabillity Testing.  Design workshops.  App design. Management",
      description:
        "Pioneer a seamless shopping experience for the Now Generation. Innovative fashion and lifestyle marketplace emerging from GCC to bring new social shopping experience",
      btnRead: "Read full casestudy",
      btnContactDesign: "Contact for Design impact",
      spanConfidential: "- Confidential",
      urlCaseStudy: '/nissnass-case-study/index.html'
    },
  ];
  // setProjectData(projects);
  // useEffect(() => {
  //   axios.get('http://localhost:5000/api/v1/projects')
  //   .then((res) => { 
  //     const projects = res.data;
  //      setProjectData(projects);
  //   })
  // }, [])
  return (
    <div className="work--sec">
      <div className="container-lg">
          <div className="head--sec">
            <h2 className="title" id="my--work">My Work</h2>
            <div className="right-sec">
              <span>Looking for designer?</span>
              <button className="btn" onClick={() => {
                    window.location.replace('#contact-us');
                  }}>let's chat</button>
            </div>
          </div>
          <div className="Projects">
            {projectData.map((item, idx) => {
              return (
                <Project
                  imgSrc={item.desktopImg}  
                  imgMobSrc={item.imgMobSrc}
                  title={item.title}
                  responsibility={item.responsibility}
                  description={item.description}
                  btnRead={item.btnRead}
                  btnContactDesign={item.btnContactDesign}
                  spanConfidential={item.spanConfidential}
                  key={idx}
                  onChildClick={openModal}
                  caseStudyLink={item.urlCaseStudy}
                />
              );
            })}
          {/* <button onClick={openModal}>Button</button> */}
        </div>
      </div>
      <CaseStudyModal onClose={closeModal} open={modalState} />
    </div>
  );
}

export default MyWork;