import React, { useState, useEffect } from "react";
import Seprator from "../Seprator/Seprator";
import "./MyExperience.css";
import MyExperienceSlider from "./MyExperienceSlider";
import SubscribeNowModal from "../Modals/SubscribeNowModal/SubscribeNowModal";
import axios from "axios";
import { Link } from "react-router-dom";

function MyExperience() {
  const [modalState, setModalState] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  const openModal = () => {
    setModalState(true);
  };

  const closeModal = () => {
    setModalState(false);
  };
  useEffect(() => {
    axios
      .get("http://localhost:1337/api/blogs?populate=*")
      .then((resp) => {
        const data = resp.data.data;
        setBlogs(data);
      })
      .catch((error) => setError(error));
  }, []);

  if (error) {
    console.log(error);
  }
  return (
    <div className="from--my--experience">
      <div className="container-lg">
        <Seprator />
        <div className="head-sec">
          <h1 className="title">From my experience</h1>
          <div className="right-sec">
            <button
              type="button"
              className="btn subscribe--now"
              onClick={openModal}
            >
              Subscribe now
            </button>
            <a
              href="https://www.youtube.com/channel/UCKXbcZ6ElFUemSrV0th2X1w"
              type="button"
              className="btn follow--youtube"
              target="_blank"
            >
              Follow my youtube
            </a>
          </div>
        </div>
        <p>
          Subscribe to be the first to find out when Nouman add new projects,
          vacancies, and more.
        </p>
        <MyExperienceSlider>
          {blogs.map((item) => {
            return (
              <Link to={`/blog-detail/${item.id}`} key={item.id}>
                <div className="experience--blog">
                  <div className="img-holder">
                    <img
                      src={`http://localhost:1337${item.attributes.image.data.attributes.url}`}
                      alt="#"
                    />
                  </div>
                  <div className="text-holder">
                    <h2 className="blog--title">{item.attributes.title}</h2>
                  </div>
                </div>
              </Link>
            );
          })}
        </MyExperienceSlider>
      </div>
      <SubscribeNowModal onClose={closeModal} open={modalState} />
    </div>
  );
}

export default MyExperience;