import React from "react";
import "./SocialLinks.css";
const SocialLinks = () => {
  return (
    <div className="social--links">
      <a
        href="https://www.linkedin.com/in/numanmehmood/"
        className="linked--in"
      >
        Linked In
      </a>
      <a href="https://www.behance.net/Banzufm" className="behance">
        Behance
      </a>
    </div>
  );
};

export default SocialLinks;
