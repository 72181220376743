import React from "react";
import Seprator from "../Seprator/Seprator";
import SocialLinks from "../SocialLinks/SocialLinks";
import flagImg from "../../assets/Images/uae-flag-icon.png";
import "./Footer.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
function Footer() {
  return (
    <div className="footer">
      <div className="container-lg">
        <Seprator />
        <div className="footer--sec">
          <div className="left--sec">
            <p>Just want to keep up with what I'm doing? Find me at: </p>
            <SocialLinks />
          </div>
          <div className="right--sec">
            <p>
              Nouman is based in{" "}
              <LazyLoadImage className="flag--icon" src={flagImg} alt="#" />
              <strong>Dubai</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
