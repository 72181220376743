import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from "react-router-dom";

import "./ResponsiveMenu.css";

function ResponsiveMenu() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  return (
    <div className="responsive-menu">
      <Link to="/" className="responsive--menu" onClick={toggleMenu}><FontAwesomeIcon icon={faBars} /></Link>
      <div className={`wrapper ${showMenu && 'active'}`}>
        <Link to="/" className="closeIcon" onClick={toggleMenu}><FontAwesomeIcon icon={faXmark} /></Link>
        <ul>
          <li>
            <a href="/">About me </a>
          </li>
          <li>
            <a href="/" onClick={() => window.scrollTo(0, 1500)}>My Work</a>
          </li>
          <li>
            <a href="/" onClick={() => window.scrollTo(0, 10000)}>1-1 mentorship</a>
          </li>
          <li>
            <a href="/jobs">Design Jobs</a>
          </li>
          {/* <li>
            <a href="#">Design recruiters</a>
          </li>
          <li>
            <a href="#">Join design community</a>
          </li> */}
          <li>
            <a href="/" onClick={() => window.scrollTo(0, 15000)}>Contact me</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ResponsiveMenu;
