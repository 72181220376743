import React from "react";
import BannerImg from "../../assets/Images/banner-img.webp";
import MobBannerImg from "../../assets/Images/banner-mob-img.webp";
import "./Banner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function Banner() {
  return (
    <div className="banner">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-xs-12 col-sm-12">
              <div className="img-holder">
                <LazyLoadImage className="desktop-banner" src={BannerImg} effect="blur" alt="#" />
                <LazyLoadImage className="mob-banner" src={MobBannerImg} effect="blur" alt="#" />
              </div>
            </div>
          <div className="col-lg-8 col-md-6 col-xs-12 col-sm-12">
              <div className="text-holder">
                <div className="head-sec">
                  <h2 className="title">
                    <strong>Hey! I’m Nouman. </strong>
                    <span>Head of Design at </span>
                    TheENTERTAINER
                  </h2>
                  <ul>
                    <li>ux speaker.</li>
                    <li>design leader.</li>
                    <li>product design mentor.</li>
                    <li>gcc award winner</li>
                  </ul>
                </div>
                <p>
                  A data & customer driven product designer and mentor based in
                  Dubai. I design products to create a meaningful impact on
                  people’s life.
                </p>
                <div className="btn-sec">
                  <button className="btn" onClick={() => {
                    window.location.replace('#contact-us');
                  }}>let's chat</button>
                  <button
                    className="btn"
                    onClick={() => {
                      window.scrollTo(0, 5580);
                    }}
                  >
                    Get 1-1 mentorship
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
