import React from "react";
import { useFormik } from "formik";
import Seprator from "../Seprator/Seprator";
import "./ContactUs.css";
// import { signUpSchema } from "../../schemas/index";
const initialValues = {
  name: "",
  email: "",
  number: "",
  textarea: "",
};

function ContactUs() {
  const { values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    // validationSchema: signUpSchema,
    onSubmit: (values) => {
      alert("Please Fill your fields");
    },
  });
  // console.log(values);
  return (
    <div>
      <div className="contact--us" id="contact-us">
        <div className="container-lg">
          <Seprator />
          <h2 className="title">Got an amazing idea/project?</h2>
          <p>
            <strong>I'm as excited as you're. </strong>Reach out to me, and
            let's create the right experience for your users.{" "}
          </p>
          <form className="contact--form" onSubmit={handleSubmit}>
            <fieldset>
              <div className="row">
                <div className="col-lg-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="name"
                    placeholder="Your Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-lg-4">
                  <input
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder="Email Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-lg-4">
                  <input
                    type="tel"
                    name="number"
                    autoComplete="off"
                    placeholder="Your whatsApp number (optional)"
                    value={values.number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <textarea
                    cols="3"
                    row="3"
                    autoComplete="off"
                    name="content"
                    placeholder="What are you looking for?"
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button type="button" className="btn submit">
                  Submit
                </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
