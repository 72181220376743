import React, { useEffect } from "react";
import Nav from "../Header/Nav/Nav";
import Logo from "./Logo/Logo";
import "./Header.css";
import ResponsiveMenu from "./ResponsiveMenu/ResponsiveMenu";
import SocialLinks from "../SocialLinks/SocialLinks";

function Header() {
  useEffect(() => {
    window.onscroll = function () {
      stickyHeader();
    };

    var header = document.getElementById("sticky-header");
    var sticky = header.offsetTop;

    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  });
  return (
      <div className="Header" id="sticky-header">
        <Logo />
        <div className="nav--section">
          <Nav />
          <SocialLinks />
          <ResponsiveMenu />
        </div>
      </div>
  );
}

export default Header;