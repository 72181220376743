import React from "react";
import Seprator from "../Seprator/Seprator";
import MentorshipColumn from "./MentorshipColumn/MentorshipColumn";
import designMentorshipImg from '../../assets/Images/design-mentorship.png';
import designCareerImg from '../../assets/Images/design-career.png';
import portfolioReviewImg from '../../assets/Images/portfolio-review.png';
import "./MentorshipProgram.css";

function MentorshipProgram() {
  return (
    <div className="mentorship--program">
      <div className="container-lg">
        <Seprator />
          <h2 className="title"  id="mentorship">1-1 Design mentorship Program</h2>
          <p>
            I aimed to train or transform designers from regular designers to
            'PRODUCT / ROI-driven designers' who can confidently work in a big
            organization and make the design department valuable.{" "}
          </p>
          <p>
            I have worked in large design-focused organizations where product
            designers need extra skills to prove themselves. You are probably an
            expert in your area but struggling to get the job, make an impact,
            or earn trust in the team. I will help you find and improve those
            minor but effective skills to become an impactful designer.
          </p>
          <p>
            <strong>
              Book 1-1 with me, and I can help you with these services:
            </strong>
          </p>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <MentorshipColumn
                icon={designMentorshipImg}
                title="1-1 Design Mentorship"
                session="30 minute session / $200.00"
                description="Book a mentoring session with me to get 1:1 advice and help with your career. "
                bookSession="https://www.superpeer.com/nouman/-/1-1-Desing-Mentorship"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <MentorshipColumn
               icon={designCareerImg}
                title="Help with your design career"
                session="45 minute session / $200.00"
                description="I’ll help you with your next design roadmap by evaluate your current career path.  "
                bookSession="https://www.superpeer.com/nouman/-/1-1-Desing-Mentorship"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <MentorshipColumn
                icon={portfolioReviewImg}
                title="Portfolio Review with me"
                session="45 minute session / $200.00"
                description="I'll review your portfolio and guide you with the proper structure preferred by hiring managers. "
                bookSession="https://www.superpeer.com/nouman/-/1-1-Desing-Mentorship"
              />
            </div>
          </div>
        </div>
      </div>
  );
}

export default MentorshipProgram;
