import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./CompanySlide.css";
function CompanySlide(props) {
  return (
    <div className="company--slide">
      <div className="img-holder">
        <LazyLoadImage src={props.imgSrc} effect="blur" alt="#" />
      </div>
      <span className="company-title">{props.companyTitle}</span>
    </div>
  );
}

export default CompanySlide;
