import React from "react";
import Banner from "../Components/Banner/Banner";
import Brands from "../Components/Brands/Brands";
import MentorshipProgram from "../Components/MentorshipProgram/MentorshipProgram";
import MyWork from "../Components/MyWork/MyWork";
import Testimonials from "../Components/Testimonials/Testimonials";
// import MyExperience from "../Components/MyExperience/MyExperience";

function Index() {
  return (
    <>
        <Banner />
        <Brands />
        <MyWork />
        <MentorshipProgram />
        <Testimonials />
        {/* <MyExperience /> */}
    </>
  );
}

export default Index;
